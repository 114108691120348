
import styled from "@emotion/styled"
import { color, typography, space, textAlign, letterSpacing, fontWeight, display } from "styled-system"

const propNames = [].concat(
  color.propNames,
  typography.propNames,
  space.propNames,
  textAlign.propNames,
  letterSpacing.propNames,
  fontWeight.propNames,
  ["as"]
)

const Heading = styled("h1", {
  shouldForwardProp: prop => propNames.indexOf(prop) === -1,
})(color, typography, space, textAlign, letterSpacing, fontWeight, display)

Heading.defaultProps = {
  fontSize: "xl",
  lineHeight: "dense",
}

export default Heading
