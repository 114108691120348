
import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import { fontSize } from "styled-system"


import PageHeader from "../components/PageHeader"


const Page = () => {


  return (
    <>
      <PageHeader 
        title="Page not founds"
        subtitle="SDsadasd "
      />

     
    </>
  )
}

export default Page
