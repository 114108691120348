import React from "react"

import Container from "../Container"
import P from "../Paragraph"
import Heading from "../Heading"


const PageHeader = ({ title, subtitle, kicker, children }) => {
  return (
    <Container pt={["10rem","12rem"]} size="sm" textAlign="center">
         <P my={0} fontSize={["xs", "xs"]} color="darkGrey">
          { kicker }
        </P>
        <Heading as="h1" fontSize={["xl", "xxl"]} letterSpacing={-0.5}>
          { title }
        </Heading>
        <P fontSize={["sm", "xl"]} color="darkGrey">
          { subtitle }
        </P>
        { children }
    </Container>
  )
}

export default PageHeader